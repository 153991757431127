import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Gallery from 'react-photo-gallery';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Heading from '../heading/Heading';
import Thumbnail from '../thumbnail/Thumbnail';
import { Link, useLocation } from 'react-router-dom';
import { getPhotoById, getFileByPath } from '../../helpers/photos';
import { photoPages } from '../../data';

import styles from './Gallery.module.scss';

const thumbnail = (props) => {
  return (
    <Link to={props.photo.slug} key={props.key}>
      <Thumbnail
        photo={props.photo}
        index={props.index}
        left={props.left}
        top={props.top}
        margin={props.margin}
      />
    </Link>
  );
};

export default function PhotoGallery(props) {
  const currentPageIndex = photoPages.findIndex((page) => page.slug === props.data.slug)

  const nextPage = photoPages.length === currentPageIndex + 1
    ? photoPages[0]
    : photoPages[currentPageIndex + 1];

  const previousPage = currentPageIndex === 0
    ? photoPages[photoPages.length - 1]
    : photoPages[currentPageIndex - 1]

  const isWideLayout = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const isUltraWideLayout = useMediaQuery({
    query: '(min-width: 1920px)'
  })

  let columns = 1;

  if (isUltraWideLayout) {
    columns = 3;
  } else if (isWideLayout) {
    columns = 2;
  } else {
    columns = 1;
  }

  const location = useLocation();
  const isHome = location.pathname === "/";

  const photos = props.data.children
    .map(photo => getPhotoById(photo.id))
    .map(photo => ({
      key: photo.name,
      src: getFileByPath(photo.src),
      width: 1,
      height: photo.meta.height / photo.meta.width,
      slug: photo.slug,
    }));
  if (columns > photos.length) {
    columns = photos.length;
  }

  return (
    <div className={styles.wrapper}>

      {!isHome &&
        <div className={styles.header}>
          <Breadcrumb />
          <Heading tag="h2">{props.data.name}</Heading>
        </div>
      }

      <div className={styles.content}>
        <Gallery
          direction="column"
          photos={photos}
          columns={columns}
          renderImage={thumbnail}
          margin={isWideLayout ? 5 : 1}
        />
      </div>
      <div className={styles.pagination}>
        <Link to={previousPage?.slug} className={styles.link}>{previousPage?.name}</Link>
        <Link to={nextPage?.slug} className={styles.link}>{nextPage?.name}</Link>
      </div>
    </div>
  );
}
