import React from 'react';

import { config } from '../../data';

import styles from './Footer.module.scss';

export default class Footer extends React.PureComponent {

  render() {
    return (
      <footer className={styles.wrapper}>
        <p>
          Copyright
          {' '}
          {config.author}
          {' '}
          2022
        </p>
      </footer>
    );
  }

}
