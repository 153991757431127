import React from 'react';
import Gallery from 'react-photo-gallery';
import Thumbnail from '../../components/thumbnail/Thumbnail';

import { photoPages } from '../../data';
import styles from './Latest.module.scss';
import { getFileByPath, getPhotoById } from '../../helpers/photos';
import { Link } from 'react-router-dom';

export default class Latest extends React.PureComponent {

  thumbnail = (props) => {
    return (
      <Thumbnail
        key={props.key}
        photo={props.photo}
        index={props.index}
        left={props.left}
        top={props.top}
        margin={props.margin}
      />
    );
  };

  render() {
    const pages = Object.values(photoPages);
    const photos = {};

    pages.forEach(page => {
      const photo = getPhotoById(page.thumbnail)

      photos[page.thumbnail] = {
        key: photo.name,
        src: getFileByPath(photo.src),
        width: 1,
        height: photo.meta.height / photo.meta.width,
        slug: photo.slug,
      }
    })

    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <ul className={styles.list}>
            {pages.map((page) => (
              <li className={styles.item} key={page.name}>
                <div className={styles.container}>
                  <Link to={page.slug}>
                    <Gallery
                      direction="column"
                      photos={[photos[page.thumbnail]]}
                      columns={1}
                      renderImage={this.thumbnail}
                      margin={10}
                    />
                  </Link>
                  <Link className={styles.centered} to={page.slug}>
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>

      </React.Fragment>
    );
  }

}
