import React from 'react';
import { Helmet } from 'react-helmet';
import Heading from '../../components/heading/Heading';
import Social from '../../components/social/Social';

import { config } from '../../data';
import styles from './About.module.scss';

export default class About extends React.PureComponent {

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About {config.title}</title>
          <link rel="canonical" href="" />
        </Helmet>

        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Heading
              tag="h2"
            >
              About {config.title}
            </Heading>
          </div>
          <div className={styles.copy}>
            <img className={styles.portrait} src="/profile.png" alt="" />
            <p>Thank you for browsing my portfolio. I am an aspiring photographer capturing everyday life from a unique perspective.</p>
            <p>
              Main inspirations:&nbsp;
              <a href="https://en.wikipedia.org/wiki/Saul_Leiter">Saul Leiter</a>,&nbsp;
              <a href="https://en.wikipedia.org/wiki/Vivian_Maier">Vivian Maier</a>,&nbsp;
              <a href="https://en.wikipedia.org/wiki/William_Eggleston">William Eggleston</a>,&nbsp;
              <a href="https://en.wikipedia.org/wiki/Fred_Herzog">Fred Herzog</a>,&nbsp;
              <a href="https://en.wikipedia.org/wiki/Ernst_Haas">Ernst Haas</a>,&nbsp;
              <a href="https://en.wikipedia.org/wiki/Diane_Arbus">Diane Arbus</a>.&nbsp;
            </p>
          </div>
          <Social
            title={"Social"}
          />
        </div>
      </React.Fragment>
    );
  }

}
